


































































































import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import FscSimpleCard from "@/components/Card/FscSimpleCard.vue";
import FscHorizontalTable from "@/components/Table/FscHorizontalTable.vue";
import PriceMixin from "@/mixins/PriceMixin";
import { namespace } from "vuex-class";
import EditButton from "@/components/Button/EditButton.vue";
import AbortButton from "@/components/Button/AbortButton.vue";
import SaveButton from "@/components/Button/SaveButton.vue";
import FscInputDecimal from "@/components/Field/FscInputDecimal.vue";
import FscMultiselect from "@/components/FscMultiselect.vue";
import Validation from "@/components/Validation.vue";
import { Validations } from "vuelidate-property-decorators";
import { ICrudOptions } from "@/interfaces/ICrudOptions";
import { IStudentEducation } from "@/interfaces/IStudentEducation";
import { required } from "vuelidate/lib/validators";
import { formatStudentName } from "@/utils/NameUtil";

const CustomBookedProductModule = namespace("custom-booked-product");
const StudentPaymentModule = namespace("student-payment");
const VatModule = namespace("vat");

type Mode = "edit" | "show";

@Component({
  components: {
    FscSimpleCard,
    FscHorizontalTable,
    EditButton,
    AbortButton,
    SaveButton,
    FscInputDecimal,
    FscMultiselect,
    Validation,
  },
})
export default class CostBearerElseEducationOtherCostsPreview extends PriceMixin {
  public name = "CostBearerElseEducationOtherCostsPreview";

  @CustomBookedProductModule.Action("findOne")
  public findCustomBookedProduct: any;

  @CustomBookedProductModule.Getter("getDataItem")
  public customBookedProduct: any;

  @CustomBookedProductModule.Action("update")
  public updateCustomBookedProduct: any;

  @CustomBookedProductModule.Getter("getSuccess")
  public customBookedProductSuccess: any;

  @StudentPaymentModule.Action("studentEducation/findAll")
  public findAllEducationsByStudent!: (opts: ICrudOptions) => Promise<void>;

  @StudentPaymentModule.Getter("studentEducation/getDataList")
  public allStudentEducations!: Array<IStudentEducation>;

  @VatModule.Action("findAll")
  public findAllVat: any;

  @VatModule.Getter("getDataList")
  public vats: any;

  @Prop()
  public customBooking!: any;

  @Prop({ type: Boolean, default: () => false })
  public hasPermission!: boolean;

  @Prop({ type: String })
  public createdByName!: string;

  protected columns = [
    {
      label: this.$t("students.amount"),
      key: "amountToPay",
    },
    {
      label: this.$tc("general.customer", 1),
      key: "student",
    },
    {
      label: this.$tc("general.license_class", 1),
      key: "licenseClass",
    },
    {
      label: this.$tc("calendar.form_date", 1),
      key: "date",
    },
    {
      label: this.$t("general.vat"),
      key: "vat",
    },
    {
      label: "",
      key: "actions",
    },
  ];

  public showAdditionalFields = false;
  public showAction = false;
  public currentMode: Mode = "show";
  public editStudentEducationLicenseClass: any | null = null;
  public editVat: any | null = null;

  public mounted(): void {
    if (this.customBooking.id) {
      this.findCustomBookedProduct({
        id: this.customBooking.id,
        resource: "custom-booking",
      });
    }
    const studentId = Number(this.$route.params.id);

    this.findAllEducationsByStudent({
      resource: `student-educations/student/${studentId}`,
    });

    this.findAllVat({
      resource: "vat-options",
    });
  }

  private get studentEducations(): Array<any> {
    return this.allStudentEducations.map((studentEducation: IStudentEducation) => {
      return {
        id: studentEducation.id,
        name: studentEducation.mainLicenseClass,
      };
    });
  }

  public get studentFullName() {
    if (this.customBookedProduct.student) {
      return formatStudentName(this.customBookedProduct.student.firstName, this.customBookedProduct.student.lastName);
    }
    return "";
  }

  public get percentageAmount() {
    if (this.customBookedProduct.vat && this.currentMode === "show") {
      return `${this.customBookedProduct.vat.percentageAmount} %`;
    } else if (this.customBookedProduct.vat && this.currentMode === "edit") {
      return this.customBookedProduct.vat;
    }
    return "";
  }

  public get licenseClass() {
    if (this.customBookedProduct.licenseClass && this.customBookedProduct.studentEducationId) {
      return {
        id: this.customBookedProduct.studentEducationId,
        name: this.customBookedProduct.licenseClass,
      };
    }
    return "";
  }

  @Watch("customBooking")
  public onTheoryLessonIdChange(customBooking: any): void {
    if (customBooking) {
      this.findCustomBookedProduct({
        id: customBooking.id,
        resource: "custom-booking",
      });
    }
  }

  public onEdit() {
    this.showAdditionalFields = false;
    this.showAction = true;
    this.currentMode = "edit";
  }

  public onClose() {
    this.showAdditionalFields = false;
    this.showAction = false;
    this.currentMode = "show";
    this.editVat = null;
  }

  public async onSave() {
    const data = {
      id: this.customBooking.id,
      studentEducationId: this.editStudentEducationLicenseClass.id,
      vat: {
        id: this.editVat.id ? this.editVat.id : null,
      },
    };
    await this.updateCustomBookedProduct({
      resource: "custom-booking",
      data: data,
      id: this.customBooking.id,
    });
    if (this.customBookedProductSuccess) {
      this.findCustomBookedProduct({
        id: this.customBooking.id,
        resource: "custom-booking",
      });
      this.$emit("refresh");
      this.onClose();
    }
  }

  @Validations()
  public validations(): Record<string, any> {
    return {
      editStudentEducationLicenseClass: { required },
      editVat: { required },
    };
  }
}
