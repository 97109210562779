import { render, staticRenderFns } from "./CostBearerNotesPreview.vue?vue&type=template&id=67fe3587&scoped=true&"
import script from "./CostBearerNotesPreview.vue?vue&type=script&lang=ts&"
export * from "./CostBearerNotesPreview.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "67fe3587",
  null
  
)

export default component.exports