var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-overlay',{staticClass:"h-100 overflow-y-auto",attrs:{"show":_vm.loading}},[_c('fsc-simple-card',{staticClass:"h-100 overflow-y-auto",scopedSlots:_vm._u([{key:"footer",fn:function(){return [_c('div',{staticClass:"btn-container"},[_c('abort-button',{staticClass:"fsc-btn-border",attrs:{"label":""},on:{"click":function($event){return _vm.onAbort()}}}),_c('save-button',{staticClass:"ml-2 fsc-btn-border",attrs:{"label":_vm.$t('general.save'),"disabled":_vm.hasNotPermissionWrite,"loading":_vm.loading},on:{"click":function($event){return _vm.onSubmit()}}})],1)]},proxy:true}])},[_c('b-row',[_c('b-col',{staticClass:"form-group",attrs:{"sm":"12"}},[_c('span',{staticClass:"font-size-16 font-weight-bold"},[_vm._v(_vm._s(_vm.$t("general.new_other_service")))])]),_c('b-col',{staticClass:"form-group",attrs:{"sm":"12"}},[_c('label',{staticClass:"font-size-14 text-tuatara-500"},[_vm._v(" "+_vm._s(_vm.$tc("calendar.form_date", 1))+" * ")]),_c('validation',{attrs:{"validations":_vm.$v.date},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return _c('Datepicker',{attrs:{"input-class":{ 'is-invalid': invalid },"label-no-date-selected":_vm.$t('calendar.label_no_date'),"label-help":_vm.$t('calendar.label_help'),"id":"form-date","disabled":_vm.hasNotPermissionWrite},model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}})}}])})],1),_c('b-col',{staticClass:"form-group",attrs:{"sm":"12"}},[_c('label',{staticClass:"font-size-14 text-tuatara-500"},[_vm._v(" "+_vm._s(_vm.$tc("general.description", 1))+" ")]),_c('validation',{attrs:{"validations":_vm.$v.freeText},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return _c('b-form-input',{class:{ 'is-invalid': invalid },attrs:{"disabled":_vm.hasNotPermissionWrite},model:{value:(_vm.freeText),callback:function ($$v) {_vm.freeText=$$v},expression:"freeText"}})}}])}),_c('div',{staticClass:"font-size-14 text-tuatara-500 d-flex justify-content-end mt-2"},[_vm._v(" "+_vm._s(_vm.$t("general.text_length_limited", { count: _vm.freeTextLength }))+" ")])],1),_c('b-col',{staticClass:"form-group",attrs:{"sm":"12"}},[_c('label',{staticClass:"font-size-14 text-tuatara-500"},[_vm._v(" "+_vm._s(_vm.$tc("students.amount", 1))+" ")]),_c('validation',{attrs:{"validations":_vm.$v.priceInEur},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return _c('fsc-input-decimal',{class:{ 'is-invalid': invalid },attrs:{"disabled":_vm.hasNotPermissionWrite},model:{value:(_vm.priceInEur),callback:function ($$v) {_vm.priceInEur=$$v},expression:"priceInEur"}})}}])})],1),_c('b-col',{staticClass:"form-group",attrs:{"sm":"12"}},[_c('label',{staticClass:"font-size-14 text-tuatara-500"},[_vm._v(" "+_vm._s(_vm.$tc("price_list.vat", 1))+" ")]),_c('validation',{attrs:{"validations":_vm.$v.vat},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return _c('fsc-multiselect',{class:{ multiselect__invalid: invalid },attrs:{"options":_vm.vats,"label":"name","appendToBody":true,"clearable":true,"deselectFromDropdown":true,"disabled":_vm.hasNotPermissionWrite},model:{value:(_vm.vat),callback:function ($$v) {_vm.vat=$$v},expression:"vat"}})}}])})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }