


































import { Component, Prop } from "vue-property-decorator";
import { mixins } from "vue-class-component";
import FscSimpleCard from "@/components/Card/FscSimpleCard.vue";
import FscHorizontalTable from "@/components/Table/FscHorizontalTable.vue";
import PriceMixin from "@/mixins/PriceMixin";

@Component({
  components: {
    FscSimpleCard,
    FscHorizontalTable,
  },
})
export default class CostBearerPaymentPreview extends mixins(PriceMixin) {
  public name = "CostBearerPaymentPreview.vue";

  @Prop()
  public selectedPayment!: any;

  protected columns = [
    {
      label: this.$t("students.amount"),
      key: "amountPaid",
    },
    {
      label: this.$tc("calendar.form_date", 1),
      key: "date",
    },
  ];

  protected get subtitle(): string {
    return this.selectedPayment.description ? this.selectedPayment.description : "";
  }
}
