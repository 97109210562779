








































































import { Component, Prop, Watch } from "vue-property-decorator";
import FscSimpleCard from "@/components/Card/FscSimpleCard.vue";
import FscHorizontalTable from "@/components/Table/FscHorizontalTable.vue";
import { namespace } from "vuex-class";
import PriceMixin from "@/mixins/PriceMixin";
import DocumentButton from "@/components/Button/DocumentButton.vue";
import { CANCELLATION_INVOICE, PARTIAL_INVOICE, FINAL_INVOICE } from "@/constants/EducationEventTypes";
import { formatStudentName } from "@/utils/NameUtil";

const PartialInvoiceModule = namespace("partial-invoice");

@Component({
  components: {
    FscSimpleCard,
    FscHorizontalTable,
    DocumentButton,
  },
})
export default class CostBearerInvoicePreview extends PriceMixin {
  public name = "CostBearerInvoicePreview.vue";

  @PartialInvoiceModule.Action("findOne")
  public findPartialInvoiceById: any;

  @PartialInvoiceModule.Getter("getDataItem")
  public partialInvoiceById: any;

  @Prop()
  public partialInvoice!: any;

  @Prop()
  public documentPreviewButtonDisable!: any;

  @Prop({ type: String })
  public createdByName!: string;

  public removeLetterHead = false;

  protected columns = [
    {
      label: this.$t("students.amount"),
      key: "amountToPay",
    },
    {
      label: this.$tc("general.customer", 1),
      key: "student",
    },
    {
      label: this.$tc("general.license_class", 1),
      key: "licenseClass",
    },
    {
      label: this.$tc("calendar.form_date", 1),
      key: "date",
    },
  ];

  public partialInvoiceLicenseClass!: any;

  public get partialInvoiceLicenceClassGet() {
    if (this.partialInvoice.licenseClass) {
      return this.partialInvoiceLicenseClass;
    }
    return "";
  }

  public get partialInvoiceType() {
    if (this.partialInvoiceById.invoiceType) {
      return `${this.partialInvoiceById.invoiceType.name}`;
    }
    return "";
  }

  public get partialInvoiceNumber() {
    if (this.partialInvoiceById.fullInvoiceNumber) {
      return `Nr. ${this.partialInvoiceById.fullInvoiceNumber}`;
    }
    return `Nr.`;
  }

  public get partialInvoiceStudentFullName() {
    if (this.partialInvoice.student) {
      return formatStudentName(this.partialInvoice.student.firstName, this.partialInvoice.student.lastName);
    }
    return "";
  }

  public get partialInvoiceTime() {
    if (this.partialInvoiceById.time) {
      const time = this.partialInvoiceById.time.split(".");
      return time[0];
    }
    return "";
  }

  public get invoiceTypeName() {
    if (this.partialInvoice.educationEventType == CANCELLATION_INVOICE) {
      return this.$t("automatic_payment.cancellation_invoice");
    }
    if (this.partialInvoice.educationEventType == PARTIAL_INVOICE) {
      return this.$t("automatic_payment.partial_invoice");
    }
    if (this.partialInvoice.educationEventType == FINAL_INVOICE) {
      return this.$t("automatic_payment.final_invoice");
    }
    return "";
  }

  public onPartialInvoiceDocumentPreview(): void {
    if (this.partialInvoiceById.studentDocumentId) {
      this.$emit("partial-doc-preview", this.partialInvoiceById.studentDocumentId, this.removeLetterHead);
    }
  }

  @Watch("partialInvoice", { immediate: true, deep: true })
  public onPartialInvoiceChange(partialInvoice: any): void {
    if (partialInvoice?.id) {
      this.partialInvoiceLicenseClass = partialInvoice.licenseClass;
      this.findPartialInvoiceById({
        id: partialInvoice.id,
        resource: "invoices",
      });
    }
  }
}
