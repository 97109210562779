






































































import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import FscSimpleCard from "@/components/Card/FscSimpleCard.vue";
import { IEducationEvent } from "@/interfaces/IEducationEvent";
import FscHorizontalTable from "@/components/Table/FscHorizontalTable.vue";
import { toDateTime } from "@/utils/DateTime";
import { ITheoryExam } from "@/interfaces/Exam/ITheoryExam";
import EducationEventDetails from "@/views/Student/EducationEvent/Details/EducationEventDetails.vue";
import DocumentButton from "@/components/Button/DocumentButton.vue";
import { IDrivingLesson } from "@/interfaces/IDrivingLesson";
import axios from "@/utils/axios";
import { AxiosResponse } from "axios";
import { mixins } from "vue-class-component";
import EducationEventRequestMixin from "@/mixins/Request/EducationEventRequestMixin";
import PriceMixin from "@/mixins/PriceMixin";
import { formatInstructorName } from "@/utils/NameUtil";

@Component({
  components: {
    EducationEventDetails,
    FscHorizontalTable,
    FscSimpleCard,
    DocumentButton,
  },
})
export default class CostBearerTheoryExamDetails extends mixins(EducationEventRequestMixin, PriceMixin) {
  public name = "CostBearerTheoryExamDetails";

  @Prop({ required: true })
  public educationEvent!: any;

  @Prop({ required: true })
  public item!: ITheoryExam;

  @Prop({ required: false, type: Boolean })
  public loading!: boolean;

  @Prop()
  public documentPreviewButtonDisable!: any;

  @Prop({ type: String })
  public createdByName!: string;

  protected columns = [
    {
      label: this.$t("event_types.product_booked"),
      key: "productBookingId",
    },
    {
      label: this.$t("event_types.tuv_booked"),
      key: "tuvProductBookingId",
    },
    {
      label: this.$tc("general.customer", 1),
      key: "student.name",
    },
    {
      label: this.$tc("general.license_class", 1),
      key: "licenseClass",
    },
    {
      label: this.$tc("calendar.form_date", 1),
      key: "date",
    },
    {
      label: this.$tc("calendar.form_time", 1),
      key: "theoryExamTime",
    },
    {
      label: this.$tc("sidebar.instructors", 1),
      key: "instructor",
    },
  ];

  public removeLetterHead = false;

  protected get theoryExam(): any {
    return (
      this.item?.studentTheoryExams?.find((x: any) => x.student.id === this.educationEvent.student.id && x.licenseClass === this.educationEvent.licenseClass) ||
      null
    );
  }

  protected get instructor(): string {
    return this.item?.users?.length
      ? `${formatInstructorName(this.item.users[0].firstName, this.item.users[0].lastName)} + ${this.item.users.length - 1}`
      : "-";
  }

  public onTheoryExamDocumentPreview(): void {
    this.$emit("theory-exam-doc-preview", this.theoryExam.protocolStudentDocumentId, this.removeLetterHead);
  }

  protected get productBooking(): any {
    if (this.theoryExam && this.theoryExam.productBookingId) {
      return this.theoryExam.productBookingId;
    }
    return "";
  }

  protected get tuvBooking(): any {
    return this.theoryExam && this.theoryExam.tuvProductBookingId ? this.theoryExam.tuvProductBookingId : "";
  }

  protected get bookedProduct(): any {
    return this.productBooked && this.productBooked.priceInEur ? this.productBooked.priceInEur : "";
  }

  protected get tuvProduct(): any {
    return this.tuvBooked && this.tuvBooked.priceInEur ? this.tuvBooked.priceInEur : "";
  }

  @Watch("item")
  public onItemChange(item: any): void {
    if (item && item.booked) {
      this.productBooking ? this.fetchProductBooked(this.productBooking) : (this.productBooked = null);
      this.tuvBooking ? this.fetchTuvBooked(this.tuvBooking) : (this.tuvBooked = null);
    }
    this.productBooked = null;
    this.tuvBooked = null;
  }
}
