















































































































































































import { Component, Prop, Watch } from "vue-property-decorator";
import FscSimpleCard from "@/components/Card/FscSimpleCard.vue";
import FscHorizontalTable from "@/components/Table/FscHorizontalTable.vue";
import PriceMixin from "@/mixins/PriceMixin";
import { namespace } from "vuex-class";
import CreateButton from "@/components/Button/CreateButton.vue";
import RemoveButton from "@/components/Button/RemoveButton.vue";
import EditButton from "@/components/Button/EditButton.vue";
import Validation from "@/components/Validation.vue";
import FscInputDecimal from "@/components/Field/FscInputDecimal.vue";
import { Validations } from "vuelidate-property-decorators";
import { minValue, required } from "vuelidate/lib/validators";
import AbortButton from "@/components/Button/AbortButton.vue";
import SaveButton from "@/components/Button/SaveButton.vue";
import { ICrudOptions } from "@/interfaces/ICrudOptions";
import { IStudentEducation } from "@/interfaces/IStudentEducation";
import DeleteModal from "@/components/Modal/DeleteModal.vue";
import { mixins } from "vue-class-component";
import ModalMixin from "@/mixins/ModalMixin";
import FscMultiselect from "@/components/FscMultiselect.vue";
import { formatStudentName } from "@/utils/NameUtil";
import ActionButton from "@/components/Button/ActionButton.vue";
import FibuFixTransactionsModal from "@/views/Student/FibuFixTransactionsModal.vue";
import FibuFixTransactionRequestMixin from "@/mixins/Request/FibuFixTransactionRequestMixin";
import { MODULE_PAYMENT_WRITE } from "@/constants/Permissions";
import { v4 as uuidv4 } from "uuid";

const StudentPaymentModule = namespace("student-payment");
const VatModule = namespace("vat");

type Mode = "create" | "edit" | "remove" | "show";

@Component({
  components: {
    FibuFixTransactionsModal,
    ActionButton,
    DeleteModal,
    SaveButton,
    AbortButton,
    FscInputDecimal,
    Validation,
    EditButton,
    RemoveButton,
    CreateButton,
    FscSimpleCard,
    FscHorizontalTable,
    FscMultiselect,
  },
})
export default class CostBearerEducationPaymentPreview extends mixins(ModalMixin, PriceMixin, FibuFixTransactionRequestMixin) {
  public name = "CostBearerEducationPaymentPreview";

  @StudentPaymentModule.Action("findOne")
  public findPaymentById: any;

  @StudentPaymentModule.Getter("getDataItem")
  public paymentItem: any;

  @StudentPaymentModule.Action("create")
  public createPayment!: (options: ICrudOptions) => Promise<any>;

  @StudentPaymentModule.Action("update")
  public updatePayment!: (options: ICrudOptions) => Promise<any>;

  @StudentPaymentModule.Action("del")
  public removePayment!: (options: ICrudOptions) => Promise<any>;

  @StudentPaymentModule.Getter("getSuccess")
  public paymentSuccess!: boolean;

  @StudentPaymentModule.Getter("getIsLoading")
  public paymentLoading!: boolean;

  @StudentPaymentModule.Action("studentEducation/findAll")
  public findAllEducationsByStudent!: (opts: ICrudOptions) => Promise<void>;

  @StudentPaymentModule.Getter("studentEducation/getDataList")
  public allStudentEducations!: Array<IStudentEducation>;

  @VatModule.Action("findAll")
  public findAllVat: any;

  @VatModule.Getter("getDataList")
  public vats: any;

  @Prop()
  public selectedPayment!: any;

  public deleteModalId = "payment-delete-modal";

  @Prop({ type: Boolean, default: () => false })
  public hasPermission!: boolean;

  @Prop({ type: String })
  public createdByName!: string;

  public showAdditionalFields = false;
  public showAction = false;

  public newAmountInEur = null;
  public newStudentEducationLicenseClass: number | null = null;
  private editAmountInEur = null;
  private editStudentEducationLicenseClass: string | null = null;
  public newVat: any = null;
  public editVat: any | null = null;

  public currentMode: Mode = "show";

  protected columns = [
    {
      label: this.$t("students.amount"),
      key: "amountToPay",
    },
    {
      label: this.$tc("general.customer", 1),
      key: "student",
    },
    {
      label: this.$tc("students.payment_method", 1),
      key: "paymentMethod",
    },
    {
      label: this.$tc("general.license_class", 1),
      key: "licenseClass",
    },

    {
      label: this.$tc("calendar.form_date", 1),
      key: "date",
    },
    {
      label: this.$t("general.vat"),
      key: "vat",
    },
    // {
    //   label: this.$tc("automatic_payment.has_fibu_fix_transaction", 1),
    //   key: "hasFibuFixTransaction",
    //   hide: (item: any) => {
    //     return item?.hasFibuFixTransaction == undefined || item?.hasFibuFixTransaction == null || !this.$can(MODULE_PAYMENT_WRITE);
    //   },
    // },
    {
      label: "",
      key: "actions",
    },
  ];

  public mounted(): void {
    this.fetchPayment();

    const studentId = Number(this.$route.params.id);

    this.findAllEducationsByStudent({
      resource: `student-educations/student/${studentId}`,
    });
    this.findAllVat({
      resource: "vat-options",
    });
  }

  public fetchPayment(): void {
    if (this.selectedPayment.id) {
      this.findPaymentById({
        id: this.selectedPayment.id,
        resource: "payments",
      });
    }
  }

  public get studentFullName() {
    if (this.selectedPayment.student) {
      return formatStudentName(this.selectedPayment.student.firstName, this.selectedPayment.student.lastName);
    }
    return "";
  }

  public get percentageAmount() {
    if (this.paymentItem.vat && this.currentMode === "show") {
      return `${this.paymentItem.vat.percentageAmount} %`;
    } else if (this.paymentItem.vat && this.currentMode === "edit") {
      return this.paymentItem.vat;
    }
    return "";
  }

  public get licenseClass() {
    if (this.selectedPayment.licenseClass) {
      return this.selectedPayment.licenseClass;
    }
    return "";
  }

  public get paymentMethod() {
    if (this.paymentItem.paymentMethod) {
      return this.paymentItem?.paymentMethod?.name;
    }
    return "";
  }

  @Watch("selectedPayment")
  public onEducationPaymentChange(selectedPayment: any): void {
    if (selectedPayment) {
      this.findPaymentById({
        id: selectedPayment.id,
        resource: "payments",
      });
    }
  }

  protected get title(): string {
    return this.selectedPayment?.description.split("|")[0] || "";
  }

  protected get subtitle(): string {
    if (this.selectedPayment?.description?.indexOf("|") > -1) {
      return this.selectedPayment.description.split("|")[1].trim();
    }
    return "";
  }

  protected get createdOn(): string {
    if (this.selectedPayment.createdOn) return this.selectedPayment.createdOn;
    if (this.selectedPayment?.date && this.selectedPayment?.time) {
      return `${this.selectedPayment?.date}T${this.selectedPayment?.time}`;
    }
    return this.selectedPayment?.date;
  }

  @Validations()
  public validations(): Record<string, any> {
    if (this.currentMode === "create") {
      return {
        newAmountInEur: {
          required,
          minValue: minValue(0),
        },
        newStudentEducationLicenseClass: {
          required,
        },
        newVat: {
          required,
        },
      };
    } else if (this.currentMode === "edit") {
      return {
        editAmountInEur: {
          required,
          minValueCustom: minValue(0),
        },
        editStudentEducationLicenseClass: {
          required,
        },
        editVat: {
          required,
        },
      };
    }
    return {};
  }

  private onCreate() {
    this.showAdditionalFields = true;
    this.showAction = true;
    this.currentMode = "create";
  }

  private onEdit() {
    this.showAdditionalFields = false;
    this.showAction = true;
    this.currentMode = "edit";
  }

  private onRemove() {
    this.showAdditionalFields = false;
    this.showAction = false;
    this.currentMode = "remove";
    this.showModal(this.deleteModalId);
  }

  private async okRemove() {
    if (this.selectedPayment.id) {
      await this.removePayment({
        id: this.selectedPayment.id,
        resource: "payments",
      });

      if (this.paymentSuccess) {
        this.$emit("refresh");
        this.$emit("on-remove-success");
      }
    }
  }

  private onClose() {
    this.showAdditionalFields = false;
    this.showAction = false;
    this.currentMode = "show";
  }

  private async onSave() {
    this.$v.$touch();
    if (!this.hasPermission && this.$v.$invalid) {
      return;
    }

    if (this.currentMode === "create") {
      await this.createPaymentDispatch();
    } else if (this.currentMode === "edit") {
      await this.updatePaymentDispatch();
    }

    if (this.paymentSuccess) {
      this.$emit("refresh");
    }
  }

  private resetFields() {
    this.newAmountInEur = null;
    this.newStudentEducationLicenseClass = null;
    this.editAmountInEur = null;
    this.editStudentEducationLicenseClass = null;
    this.newVat = null;
    this.editVat = null;
  }

  private async createPaymentDispatch() {
    await this.createPayment({
      resource: "payments/createWithoutInvoice",
      data: {
        ...this.paymentItem,
        id: null,
        amountInEur: this.newAmountInEur,
        studentEducationId: this.newStudentEducationLicenseClass,
        vat: {
          id: this.newVat.id ? this.newVat.id : null,
        },
      },
    });

    if (this.paymentSuccess) {
      this.$emit("refresh");
      this.onClose();
      this.resetFields();
    }
  }

  private async updatePaymentDispatch() {
    const data = {
      ...this.paymentItem,
      id: this.selectedPayment.id,
      amountInEur: this.editAmountInEur,
      licenseClassEnum: this.editStudentEducationLicenseClass,
      vat: {
        id: this.editVat.id ? this.editVat.id : null,
      },
    };

    await this.updatePayment({
      resource: "payments",
      data: data,
      id: this.selectedPayment.id,
    });

    if (this.paymentSuccess) {
      this.fetchPayment();
      this.$emit("refresh");
      this.onClose();
      this.resetFields();
    }
  }

  private get studentEducations(): Array<any> {
    return this.allStudentEducations.map((studentEducation: IStudentEducation) => {
      return studentEducation.mainLicenseClass;
    });
  }

  private get studentEducationsForCreate(): Array<any> {
    return this.allStudentEducations.map((studentEducation: IStudentEducation) => {
      return {
        mainLicenseClass: studentEducation.mainLicenseClass,
        id: studentEducation.id,
      };
    });
  }

  private fibufixTransactionsModalId = `fibufixTransactionsModalId-${this.uuid}`;

  protected get uuid(): any {
    return uuidv4();
  }

  private handleFibufixTransactions() {
    this.$bvModal.show(this.fibufixTransactionsModalId);

    if (this.paymentItem?.paymentMethod?.id) {
      this.getFibuFixTransactions(this.paymentItem.paymentMethod.id, this.selectedPayment.id);
    }
  }

  private get fibufixItems() {
    return this.fibuFixTransactions;
  }

  private async resendFibufixTransactions() {
    await this.resendFibuFixTransactions(this.selectedPayment.id);

    if (this.fibuFixResendTransactionSuccess) {
      this.$emit("refresh");
    }
  }

  private get showResendTransaction() {
    return this.paymentItem?.hasFibuFixTransaction !== undefined && this.paymentItem?.hasFibuFixTransaction !== null;
  }
}
