





























































































































































































































import { Component, Watch } from "vue-property-decorator";
import CrudService from "@/services/CrudService";
import { IEducationEvent } from "@/interfaces/IEducationEvent";
import { AxiosResponse } from "axios";
import FscPageHeader from "@/components/FscPageHeader.vue";
import AbortButton from "@/components/Button/AbortButton.vue";
import FscSimpleCard from "@/components/Card/FscSimpleCard.vue";
import { mixins } from "vue-class-component";
import Table from "@/components/Table.vue";
import CostBearerBookingForm from "@/views/CostBearers/Services/CostBearerBookingForm.vue";
import Actions from "@/components/Actions.vue";
import CreateButton from "@/components/Button/CreateButton.vue";
import { namespace } from "vuex-class";
import { ICrudOptions } from "@/interfaces/ICrudOptions";
import { IBasicNamedDTO } from "@/interfaces/IBasicNamedDTO";
import { ICostBearerBooking } from "@/interfaces/ICostBearerBooking";
import DrivingLessonDetails from "@/views/Student/EducationEvent/Details/DrivingLessonDetails.vue";
import PracticalExamDetails from "@/views/Student/EducationEvent/Details/PracticalExamDetails.vue";
import TheoryExamDetails from "@/views/Student/EducationEvent/Details/TheoryExamDetails.vue";
import EducationClassicPayPreview from "@/views/Student/EducationEvent/Details/EducationClassicPayPreview.vue";
import EducationEventTypeMixin from "@/mixins/EducationEventTypeMixin";
import EducationDocumentPreview from "@/views/Student/EducationEvent/EducationDocumentPreview.vue";
import InvoicePreview from "@/views/Student/EducationEvent/Details/InvoicePreview.vue";
import ProductBookingPreview from "@/views/Student/EducationEvent/Details/ProductBookingPreview.vue";
import EducationStudentDocumentPreview from "@/views/Student/EducationEvent/Details/EducationStudentDocumentPreview.vue";
import EducationTheoryLessonPreview from "@/views/Student/EducationEvent/Details/EducationTheoryLessonPreview.vue";
import EducationOtherCostsPreview from "@/views/Student/EducationEvent/Details/EducationOtherCostsPreview.vue";
import EducationNotesPreview from "@/views/Student/EducationEvent/Details/EducationNotesPreview.vue";
import InvoicePreviewPdfDocument from "@/views/Student/EducationEvent/Details/InvoicePreviewPdfDocument.vue";
import LivePayDetails from "@/views/Student/EducationEvent/Details/LivePayDetails.vue";
import CostBearerMixin from "@/mixins/CostBearerMixin";
import MailServicePreview from "@/views/Student/EducationEvent/Details/MailServicePreview.vue";
import EducationCostBearerPaymentRequestPreview from "@/views/Student/EducationEvent/Details/EducationCostBearerPaymentRequestPreview.vue";
import CostBearerPreviewPdfDocument from "@/views/Student/EducationEvent/Details/CostBearerPreviewPdfDocument.vue";
import EducationCostBearerInvoicePreview from "@/views/Student/EducationEvent/Details/EducationCostBearerInvoicePreview.vue";
import i18n from "@/i18n";
import CostBearerServiceModal from "@/views/CostBearers/Services/CostBearerServiceModal.vue";
import EducationEventNoteForm from "@/views/Student/EducationEvent/EducationEventNoteForm.vue";
import CostBearerNotesPreview from "@/views/CostBearers/Services/CostBearerNotesPreview.vue";
import RemoveButton from "@/components/Button/RemoveButton.vue";
import FscModal from "@/components/Modal/FscModal.vue";
import { PAYMENT_REQUEST, PROOF_OF_TRAINING, STUDENT_DOCUMENT } from "@/constants/EducationEventTypes";
import StudentRequestMixin from "@/mixins/Request/StudentRequestMixin";
import EducationOtherCostsCostBearerPreview from "@/views/Student/EducationEvent/Details/EducationOtherCostsCostBearerPreview.vue";
import CostBearerProductBookingPreview from "@/views/CostBearers/Services/CostBearerProductBookingPreview.vue";
import CostBearerEducationInvoicePreview from "@/views/CostBearers/Services/CostBearerEducationInvoicePreview.vue";
import CostBearerDrivingLessonDetails from "@/views/CostBearers/Services/CostBearerDrivingLessonDetails.vue";
import CostBearerTheoryExamDetails from "@/views/CostBearers/Services/CostBearerTheoryExamDetails.vue";
import CostBearerMailServicePreview from "@/views/CostBearers/Services/CostBearerMailServicePreview.vue";
import CostBearerEducationOtherCostsPreview from "@/views/CostBearers/Services/CostBearerEducationOtherCostsPreview.vue";
import CostBearerInvoicePreview from "@/views/CostBearers/Services/CostBearerInvoicePreview.vue";
import CostBearerElseEducationOtherCostsPreview from "@/views/CostBearers/Services/CostBearerElseEducationOtherCostsPreview.vue";
import CostBearerEducationStudentDocumentPreview from "@/views/CostBearers/Services/CostBearerEducationStudentDocumentPreview.vue";
import CostBearerEducationTheoryLessonPreview from "@/views/CostBearers/Services/CostBearerEducationTheoryLessonPreview.vue";
import CostBearerEducationPaymentRequestPreview from "@/views/CostBearers/Services/CostBearerEducationPaymentRequestPreview.vue";
import CostBearerPracticalExamDetails from "@/views/CostBearers/Services/CostBearerPracticalExamDetails.vue";
import CostBearerEducationClassicPayPreview from "@/views/CostBearers/Services/CostBearerEducationClassicPayPreview.vue";
import CostBearerEducationNotesPreview from "@/views/CostBearers/Services/CostBearerEducationNotesPreview.vue";
import CostBearerLivePayDetails from "@/views/CostBearers/Services/CostBearerLivePayDetails.vue";
import CostBearerEducationPaymentPreview from "@/views/CostBearers/Services/CostBearerEducationPaymentPreview.vue";
import CostBearerPaymentPreview from "@/views/CostBearers/Services/CostBearerPaymentPreview.vue";
import CostBearerProofOfTrainingPreview from "@/views/CostBearers/Services/CostBearerProofOfTrainingPreview.vue";
import { formatStudentName } from "@/utils/NameUtil";

const crudService = new CrudService();

const VatModule = namespace("vat");
const CostBearerCustomBookingModule = namespace("cost-bearer-custom-booking");
const CostBearerModule = namespace("cost-bearer");
const CostBearerNoteModule = namespace("cost-bearer-note");

@Component({
  components: {
    CostBearerNotesPreview,
    EducationCostBearerInvoicePreview,
    EducationCostBearerPaymentRequestPreview,
    CostBearerPreviewPdfDocument,
    MailServicePreview,
    LivePayDetails,
    InvoicePreviewPdfDocument,
    EducationNotesPreview,
    EducationOtherCostsPreview,
    EducationTheoryLessonPreview,
    EducationStudentDocumentPreview,
    ProductBookingPreview,
    InvoicePreview,
    EducationDocumentPreview,
    EducationClassicPayPreview,
    TheoryExamDetails,
    PracticalExamDetails,
    DrivingLessonDetails,
    CreateButton,
    Actions,
    CostBearerBookingForm,
    Table,
    FscSimpleCard,
    AbortButton,
    FscPageHeader,
    CostBearerServiceModal,
    EducationEventNoteForm,
    RemoveButton,
    FscModal,
    EducationOtherCostsCostBearerPreview,
    CostBearerProductBookingPreview,
    CostBearerEducationInvoicePreview,
    CostBearerDrivingLessonDetails,
    CostBearerTheoryExamDetails,
    CostBearerMailServicePreview,
    CostBearerEducationOtherCostsPreview,
    CostBearerInvoicePreview,
    CostBearerElseEducationOtherCostsPreview,
    CostBearerEducationStudentDocumentPreview,
    CostBearerEducationTheoryLessonPreview,
    CostBearerEducationPaymentRequestPreview,
    CostBearerPracticalExamDetails,
    CostBearerEducationClassicPayPreview,
    CostBearerEducationNotesPreview,
    CostBearerLivePayDetails,
    CostBearerEducationPaymentPreview,
    CostBearerPaymentPreview,
    CostBearerProofOfTrainingPreview,
  },
})
export default class CostBearerServices extends mixins(EducationEventTypeMixin, CostBearerMixin, StudentRequestMixin) {
  public name = "CostBearerServices";

  @VatModule.Action("findAll")
  public findAllVat!: (options: ICrudOptions) => Promise<void>;

  @VatModule.Getter("getDataList")
  public vats!: Array<IBasicNamedDTO>;

  @CostBearerCustomBookingModule.Action("create")
  public costBearerCustomBookingCreate!: (options: ICrudOptions) => Promise<void>;

  @CostBearerCustomBookingModule.Getter("getSuccess")
  public getCostBearerCustomBookingSuccess!: boolean;

  @CostBearerCustomBookingModule.Getter("getIsLoading")
  public getCostBearerCustomBookingLoading!: boolean;

  @CostBearerModule.Getter("getSelectedRowName")
  public getSelectedRowName!: string;

  @CostBearerNoteModule.Action("create")
  public costBearerNoteCreate!: any;

  @CostBearerNoteModule.Getter("getSuccess")
  public getCostBearerNoteSuccess!: boolean;

  @CostBearerNoteModule.Getter("getIsLoading")
  public getCostBearerNoteLoading!: boolean;

  protected educationEvents: IEducationEvent[] = [];
  protected educationEventsLoading = false;
  protected educationEventsFields = [
    {
      key: "date",
      label: this.$t("calendar.form_date"),
      sortable: false,
    },
    {
      key: "time",
      label: this.$t("calendar.form_time"),
      sortable: false,
    },
    {
      key: "status",
      label: this.$t("general.status"),
      sortable: false,
      class: "text-center",
    },
    {
      key: "description",
      label: this.$t("roles.description"),
      sortable: false,
    },
    {
      key: "student",
      label: this.$t("sidebar.students"),
      sortable: false,
    },
    {
      key: "amountToPay",
      label: this.$t("students.amount_to_pay"),
      sortable: false,
      thStyle: "text-align: end",
    },
    {
      key: "amountPaid",
      label: this.$t("students.amount_paid"),
      sortable: false,
      thStyle: "text-align: end",
    },
  ];

  public radioOptions = [
    {
      text: `${i18n.tc("calendar.other", 1)} ${i18n.t("automatic_payment.subject_type_app")}`,
      value: "booking",
    },
    { text: i18n.t("general.note"), value: "note" },
  ];

  public costBearerServiceModalId = "cost-bearer-service-modal-id";
  public visibleCostBearerBookingForm = false;
  public visibleCostBearerNoteForm = false;

  protected noteData = {
    note: "",
    date: null,
  };

  protected headerLabel = "";

  protected deleteServiceModalId = "cost-bearer-delete-service-modal-id";
  public deleteServiceMessage = "";

  public mounted(): void {
    this.fetchOnMounted();
    this.headerLabel = this.getSelectedRowName || "";
  }

  public fetchOnMounted(): void {
    this.fetchCostBearerEducationEvents();
  }

  protected async fetchCostBearerEducationEvents(): Promise<void> {
    this.educationEventsLoading = true;
    return await crudService
      .findOne({ resource: `/education-events/cost-bearer`, id: this.id })
      .then((response: AxiosResponse) => {
        this.educationEvents = response.data;
      })
      .finally(() => {
        this.educationEventsLoading = false;
      });
  }

  protected get id(): number {
    return Number(this.$route.params.id);
  }

  protected get costBearer(): any {
    return this.$route.params.costBearer;
  }

  protected async onCostBearerBooking(data: ICostBearerBooking): Promise<void> {
    await this.costBearerCustomBookingCreate({
      resource: "/cost-bearer-custom-booking",
      data,
    });
    if (this.getCostBearerCustomBookingSuccess) {
      await this.fetchCostBearerEducationEvents();
      this.visibleCostBearerBookingForm = false;
      (this.$refs.costBearerBookingForm as CostBearerBookingForm).resetFields();
    }
  }

  protected formatStudent(item: any): string {
    return formatStudentName(item.student?.firstName, item.student?.lastName);
  }

  protected async refreshLivePayHandle(educationEventId: number, livePayPaymentId: number): Promise<void> {
    await this.livePayPaymentUpdateStatus(livePayPaymentId);
    if (this.getLivePayPaymentUpdateStatusSuccess) {
      this.fetchOnMounted();
      this.fetchLivePayPayment(educationEventId);
    }
  }

  public onSubmitCostBearerServiceModal(radioSelected: any) {
    switch (radioSelected) {
      case "booking":
        this.onCloseAllForms();
        this.visibleCostBearerNoteForm = false;
        this.visibleCostBearerBookingForm = true;
        break;
      case "note":
        this.onCloseAllForms();
        this.visibleCostBearerBookingForm = false;
        this.visibleCostBearerNoteForm = true;
        break;
      default:
        return;
    }
    this.$bvModal.hide(this.costBearerServiceModalId);
  }

  protected onCloseNoteForm(): void {
    this.visibleCostBearerNoteForm = false;
  }

  protected onCloseCostBearerBookingForm(): void {
    this.visibleCostBearerBookingForm = false;
  }

  protected async onSubmitEducationNoteForm(): Promise<void> {
    const note = {
      costBearer: {
        id: this.id,
        //name: this.costBearer.name,
      },
      note: this.noteData.note,
      date: this.noteData.date
    };
    await this.costBearerNoteCreate({
      resource: "/cost-bearer-note",
      data: note,
    });
    if (this.getCostBearerNoteSuccess) {
      this.visibleCostBearerNoteForm = false;
      await this.fetchCostBearerEducationEvents();
      this.resetNoteForm();
    }
  }

  public resetNoteForm(): void {
    this.noteData.note = "";
    this.noteData.date = null;
  }

  @Watch("selectedRow")
  public onSelectedRowChange(row: any): void {
    this.visibleCostBearerNoteForm = false;
    this.visibleCostBearerBookingForm = false;
  }

  public costBearerBookingFormOnMounted() {
    this.findAllVat({ resource: "vat-options" });
  }

  public get deleteServiceInfoMessage() {
    return this.deleteServiceMessage;
  }

  public onRemoveService() {
    this.deleteServiceMessage = "";
    if (this.deleteServiceIsAllowed) {
      if (this.selectedRow && this.selectedRow.educationEventType === PAYMENT_REQUEST) {
        this.deleteServiceMessage = this.$tc("messages.delete_payment_request");
      } else if ((this.selectedRow && this.selectedRow.educationEventType === STUDENT_DOCUMENT) || this.selectedRow.educationEventType === PROOF_OF_TRAINING) {
        this.deleteServiceMessage = this.$tc("messages.delete_payment_request");
      } else {
        this.deleteServiceMessage = this.$tc("messages.delete_service_agreement_message", 1);
      }
    } else {
      this.deleteServiceMessage = this.$tc("messages.delete_service_forbidden_message", 1);
    }

    this.$bvModal.show(this.deleteServiceModalId);
  }

  protected cancelDeleteService() {
    this.deleteServiceMessage = "";
    this.$bvModal.hide(this.deleteServiceModalId);
  }

  protected async triggerDeleteService() {
    this.$bvModal.hide(this.deleteServiceModalId);
    await this.onDeleteSelectedService();
    await this.fetchOnMounted();
  }

  public invoicePreviewPdfDocumentOnMounted() {
    if (this.studentId) {
      this.fetchStudent(this.studentId);
    }
  }

  public get studentId() {
    return this.selectedRow?.student?.id;
  }

  public onRowClickedOverride(ctx: any) {
    this.onRowClicked(ctx, true);
  }
}
