






















































import { Component, Prop, Watch } from "vue-property-decorator";
import FscSimpleCard from "@/components/Card/FscSimpleCard.vue";
import FscHorizontalTable from "@/components/Table/FscHorizontalTable.vue";
import { namespace } from "vuex-class";
import PriceMixin from "@/mixins/PriceMixin";
import DocumentButton from "@/components/Button/DocumentButton.vue";
import { formatStudentName } from "@/utils/NameUtil";

const PartialInvoiceModule = namespace("partial-invoice");

@Component({
  components: {
    FscSimpleCard,
    FscHorizontalTable,
    DocumentButton,
  },
})
export default class CostBearerEducationInvoicePreview extends PriceMixin {
  public name = "CostBearerEducationInvoicePreview";

  @PartialInvoiceModule.Action("findOne")
  public findCostBearerInvoiceById: any;

  @PartialInvoiceModule.Getter("getDataItem")
  public costBearerInvoiceById: any;

  @Prop()
  public costBearerInvoice!: any;

  @Prop()
  public documentPreviewButtonDisable!: any;

  protected columns = [
    {
      label: this.$t("students.amount"),
      key: "amountToPay",
    },
    {
      label: this.$tc("calendar.form_date", 1),
      key: "date",
    },
  ];

  public removeLetterHead = false;

  public get partialInvoiceNumber() {
    if (this.costBearerInvoiceById.fullInvoiceNumber) {
      return `Nr. ${this.costBearerInvoiceById.fullInvoiceNumber}`;
    }
    return `Nr.`;
  }

  public get costBearerInvoiceTime() {
    if (this.costBearerInvoiceById.time) {
      const time = this.costBearerInvoiceById.time.split(".");
      return time[0];
    }
    return "";
  }

  public onCostBearerDocumentPreview(): void {
    if (this.costBearerInvoiceById.costBearerDocumentId) {
      this.$emit("cost-bearer-doc-preview", this.costBearerInvoiceById.costBearerDocumentId, this.removeLetterHead);
    }
  }

  @Watch("costBearerInvoice", { immediate: true, deep: true })
  public onPartialInvoiceChange(costBearerInvoice: any): void {
    if (costBearerInvoice) {
      this.findCostBearerInvoiceById({
        id: costBearerInvoice.id,
        resource: "invoices",
      });
    }
  }
}
