





























































import { Component, Prop } from "vue-property-decorator";
import FscSimpleCard from "@/components/Card/FscSimpleCard.vue";
import FscHorizontalTable from "@/components/Table/FscHorizontalTable.vue";
import PriceMixin from "@/mixins/PriceMixin";
import DocumentButton from "@/components/Button/DocumentButton.vue";
import { mixins } from "vue-class-component";
import CostBearerRequestMixin from "@/mixins/Request/CostBearerRequestMixin";
import has from "lodash/has";

@Component({
  methods: { has },
  components: {
    FscSimpleCard,
    FscHorizontalTable,
    DocumentButton,
  },
})
export default class CostBearerEducationPaymentRequestPreview extends mixins(PriceMixin, CostBearerRequestMixin) {
  public name = "CostBearerEducationPaymentRequestPreview";

  @Prop()
  public costBearerPaymentRequest!: any;

  @Prop()
  public documentPreviewButtonDisable!: any;

  public removeLetterHead = false;

  protected columns = [
    {
      label: this.$t("students.amount"),
      key: "amountInEur",
    },
    {
      label: this.$tc("calendar.form_date", 1),
      key: "date",
    },
    {
      label: this.$tc("general.driving_instructor", 1),
      key: "instructor",
    },
  ];

  public mounted(): void {
    this.fetchCostBearerPaymentRequest(this.costBearerPaymentRequest.id);
  }

  protected get instructorFullName(): string {
    return this.paymentRequest?.user ? `${this.paymentRequest.user.firstName} ${this.paymentRequest.user.lastName}` : "";
  }

  public onCostBearerDocumentPreview(): void {
    this.$emit("cost-bearer-doc-preview", this.paymentRequest.costBearerDocumentId, this.removeLetterHead);
  }

  public get paymentRequestTime() {
    if (this.paymentRequest.time) {
      const time = this.paymentRequest.time.split(".");
      return time[0];
    }
    return "";
  }

  public get classicPayNumber() {
    if (this.costBearerPaymentRequest.description) {
      const description = this.costBearerPaymentRequest.description.split("|");
      return description[1];
    }
    return " ";
  }
  public get paymentRequest() {
    return this.costBearerPaymentRequestResponse;
  }
}
