






















import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import { namespace } from "vuex-class";
import FscSimpleCard from "@/components/Card/FscSimpleCard.vue";
import PrintButton from "@/components/Button/PrintButton.vue";
import MailButton from "@/components/Button/MailButton.vue";
import AbortButton from "@/components/Button/AbortButton.vue";
import router from "@/router";
import UserService from "@/services/UserService";
import PaymentDocumentsEmailForm from "@/views/AutomaticPaymentsOverview/PaymentDocumentsEmailForm.vue";
import { $axios } from "@/utils/axios";

const CostBearerModule = namespace("cost-bearer-document");
const MailboxModule = namespace("mailbox");
const EmailsModule = namespace("emails");
@Component({
  components: { PaymentDocumentsEmailForm, AbortButton, MailButton, FscSimpleCard, PrintButton },
})
export default class CostBearerPreviewPdfDocument extends Vue {
  public name = "CostBearerPreviewPdfDocument";

  @CostBearerModule.Action("findCostBearerDocumentWithLetterHead")
  public findCostBearerDocument: any;

  @CostBearerModule.Getter("getMimeType")
  public mimeType: any;

  @CostBearerModule.Getter("getDataItem")
  public blob: any;

  @CostBearerModule.Getter("getIsLoading")
  public isLoading: any;

  @MailboxModule.Action("setCostBearerInfo")
  public setCostBearerInfo: any;

  @MailboxModule.Action("setBlobFile")
  public setBlobFile: any;

  @EmailsModule.Action("setBlobFile")
  public setEmailBlobFile: any;

  @Prop()
  public costBearerDocumentId!: any;

  @Prop()
  public costBearer!: any;

  private source = "";

  @Prop()
  public removeLetterHead!: any;
  private user: any = this.currentUser || null;
  public emailFormVisible = false;
  public previewFormVisible = false;

  public currentReceiver: any = null;

  public get currentUser(): any {
    return UserService.getUser();
  }

  public get receiverNameAndEmail() {
    if (!this.costBearer) return this.currentReceiver;
    const { name, email } = this.costBearer;
    this.currentReceiver = { name, email };
    return {
      name,
      email,
    };
  }

  public async getCostBearer() {
    if (!this.$route.params.costBearer) {
      const costBearerId = this.$route.params.id;
      return await $axios
        .get(`/cost-bearers/${costBearerId}`)
        .then((response: any) => {
          if (response.data) {
            const { name, email } = response.data;
            return { name, email };
          }
        })
        .catch(() => {
          return null;
        });
    }
  }

  public mounted(): void {
    if (this.costBearerDocumentId) {
      const options = {
        id: this.costBearerDocumentId,
        removeLetterhead: this.removeLetterHead,
      };
      this.findCostBearerDocument(options);
      this.previewFormVisible = true;
    }
  }

  public onPdfDocumentPrint(): void {
    console.log("on Document print");
  }

  public async onPdfDocumentEmail(): Promise<void> {
    this.setCostBearerInfo(this.costBearer);
    if (!this.currentReceiver) {
      this.currentReceiver = await this.getCostBearer();
    }
    const options = {
      blob: this.blob ? this.blob : null,
      mimeType: this.mimeType ? this.mimeType : null,
    };
    this.setBlobFile(options);
    this.setEmailBlobFile(options);
    this.emailFormVisible = true;
    this.previewFormVisible = false;
  }

  public onEmailAbort() {
    this.emailFormVisible = false;
    this.previewFormVisible = true;
  }

  @Watch("blob")
  public onCostBearerDocumentChange(blob: any) {
    if (blob) {
      this.source =
        "data:" +
        this.mimeType +
        ";base64," +
        //@ts-ignore
        new Buffer(blob, "binary").toString("base64");
    }
  }

  @Watch("costBearerDocumentId")
  public onItemChange(id: any): void {
    if (id) {
      const options = {
        id: id,
        removeLetterhead: this.removeLetterHead,
      };
      this.findCostBearerDocument(options);
    }
  }
}
